import * as React from "react"
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import { Helmet } from 'react-helmet';
import loadable from '@loadable/component'

import {useTranslation} from 'gatsby-plugin-react-i18next'
import{graphql} from "gatsby"
 const ContactForm = loadable(() => import('../components/contact/form'))
 ContactForm.preload()
const Contact = () => {
  const {t} = useTranslation();
  
  return(
    <>
      <HeardTopWrapper title={t('contactUs')}/>
      <Layout>
        <Helmet>
          <title>{t('myKitchenInspector')}｜{t('contactUs')}</title>
        </Helmet>
        <PageContainer>
          <ContactForm />
        </PageContainer>
      </Layout>
    </>
    
  )
}

export default Contact
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
